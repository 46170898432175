import React from 'react';
import {ThemeProvider} from "styled-components";
import lightMode, {darkMode} from "../styles/themeContext";
import GlobalStyles from "../styles/global";
import Layout from "../components/Layout";
import {useDarkMode} from "../hooks/useDarkMode";

const ProjectKlapp = () => {
  const [theme, bg, toggleTheme] = useDarkMode();

  return (
    <ThemeProvider theme={theme === 'light' ? lightMode : darkMode}>
      <GlobalStyles />
      <Layout
        bg={bg}
        theme={theme}
        toggleTheme={toggleTheme}
        contentType="project"
        name="/projectKlapp"
      />
    </ThemeProvider>
  );
};

export default ProjectKlapp;